import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BodyClassName from 'react-body-classname';

const UnderConstruct = () => {
  return (
    <>
      <BodyClassName className="dirt-bg">
        <div className="container text-center">
          <div className="card bg-dark shadow">
            <div className="card-body m-4">
              <h1 className="text-white">Minecraft India</h1>
              <hr className="bg-white" />
              <h2 className="text-white mb-5">Site is Under construction</h2>
              <h3 className="text-white">Current Progress</h3>
              <div class="progress" style={{ height: '25px' }}>
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style={{ width: '10%' }}>10%</div>
              </div>

            </div>
          </div>
          <div className="card-body bg-dark my-4 p-3 shadow">
            <h4 className="text-white">So.... For Now... You can..</h4>
            <a type="button" class="btn btn-info btn-lg" href="https://www.facebook.com/groups/minecraftindia.in">Visit our FB Community</a>
            <span className="text-white m-3">or</span>
            <a type="button" class="btn btn-primary btn-lg" href="https://discord.gg/796DGvj3p5" >Visit Discord Server</a>

          </div>
        </div>
      </BodyClassName>
    </>
  )
}

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <button type="button" class="btn btn-primary">Primary</button>
    <button type="button" class="btn btn-secondary">Secondary</button>
    <button type="button" class="btn btn-success">Success</button>
    <button type="button" class="btn btn-danger">Danger</button>
    <button type="button" class="btn btn-warning">Warning</button>
    <button type="button" class="btn btn-info">Info</button>
    <button type="button" class="btn btn-light">Light</button>
    <button type="button" class="btn btn-dark">Dark</button>

    <button type="button" class="btn btn-link">Link</button>
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p>
  </Layout>
)

export default UnderConstruct
